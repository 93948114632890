var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getListPendingDevices,"data-test":"dataTable-field","items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberPendingDevices,"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.hostname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'detailsDevice', params: { id: item.uid } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.info.pretty_name",fn:function(ref){
var item = ref.item;
return [_c('DeviceIcon',{attrs:{"icon-name":item.info.id,"data-test":"deviceIcon-component"}}),_vm._v(" "+_vm._s(item.info.pretty_name)+" ")]}},{key:"item.request_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")([item.last_seen],"ddd, MMM Do YY, h:mm:ss a"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DeviceActionButton',{attrs:{"uid":item.uid,"action":"accept","data-test":"DeviceActionButtonAccept-component"},on:{"update":_vm.refresh}}),_c('DeviceActionButton',{attrs:{"uid":item.uid,"action":"reject","data-test":"deviceActionButtonReject-component"},on:{"update":_vm.refresh}})]}}],null,true)},[_c('template',{slot:"no-data"},[_vm._v(" There are no more pending devices ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }